import { Enterprise } from '../interfaces';
import { UserRole } from './roles';

const KEY_USER = 'mt_currentUser';
const KEY_TOKENS = 'mt_tokens';
const KEY_PERMISSIONS = 'mt_permissions';
const KEY_LOCALE = 'mt_locale';
const KEY_EMAIL = 'mt_email';
const KEY_ENTERPRISE_INFO = 'mt_enterprise_info';

export interface UserType {
  email: string;
  userId: string;
  displayName: string;
  role: UserRole;
}

export interface TokenType {
  idToken: string;
  refreshToken: string;
  accessToken: string;
}

export interface PermissionType {
  [permission: string]: boolean;
}

const getStorage = (key: string) => {
  const obj = localStorage.getItem(key);
  return obj ? JSON.parse(obj) : null;
};

export const userStorage = {
  set: (data: UserType) => {
    localStorage.setItem(KEY_USER, JSON.stringify(data));
  },
  get: (): UserType | null => {
    return getStorage(KEY_USER);
  },
  remove: () => {
    localStorage.removeItem(KEY_USER);
  },
};

export const tokenStorage = {
  set: (data: TokenType) => {
    localStorage.setItem(KEY_TOKENS, JSON.stringify(data));
  },
  get: (): TokenType | null => {
    return getStorage(KEY_TOKENS);
  },
  remove: () => {
    localStorage.removeItem(KEY_TOKENS);
  },
};

export const permissionStorage = {
  set: (data: PermissionType) => {
    localStorage.setItem(KEY_PERMISSIONS, JSON.stringify(data));
  },
  get: (): PermissionType | null => {
    return getStorage(KEY_PERMISSIONS);
  },
  remove: () => {
    localStorage.removeItem(KEY_PERMISSIONS);
  },
};

export const localeStorage = {
  set: (data: string) => {
    localStorage.setItem(KEY_LOCALE, data);
  },
  get: (): string | null => {
    return localStorage.getItem(KEY_LOCALE);
  },
  remove: () => {
    localStorage.removeItem(KEY_LOCALE);
  },
};

export const emailStorage = {
  set: (email: string) => {
    localStorage.setItem(KEY_EMAIL, email);
  },
  get: (): string | null => {
    return localStorage.getItem(KEY_EMAIL);
  },
  remove: () => {
    localStorage.removeItem(KEY_EMAIL);
  },
};

export const enterpriseInfoStorage = {
  set: (data: Enterprise) => {
    localStorage.setItem(KEY_ENTERPRISE_INFO, JSON.stringify(data));
  },
  get: (): Enterprise | null => {
    return getStorage(KEY_ENTERPRISE_INFO);
  },
  remove: () => {
    localStorage.removeItem(KEY_ENTERPRISE_INFO);
  },
};
