import 'typeface-roboto';
import './index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);
