import { Box, Button, Popover, Typography } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import React from 'react';
import { Subject } from 'rxjs';

import { UserRole } from '../utils/roles';
import { enterpriseInfoStorage, userStorage } from '../utils/storage';

const subscriberSource = new Subject<string>();
const subscriber$ = subscriberSource.asObservable();

const getFormattedLabel = () => {
  const user = userStorage.get();
  if (user) {
    const enterpriseInfo = enterpriseInfoStorage.get();
    switch (user.role) {
      case UserRole.ENTERPRISESUPERADMIN:
        if (enterpriseInfo && enterpriseInfo.google_enterprise_token) {
          return (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <Actions label={enterpriseInfo.enterprise_display_name!} />
          );
        } else if (enterpriseInfo) {
          return (
            enterpriseInfo.enterprise_display_name || enterpriseInfo.reg_number
          );
        }
        return '';
      case UserRole.ENTERPRISEADMIN:
        return enterpriseInfo?.enterprise_display_name || '';
      case UserRole.MTANIUMADMIN:
        return 'mTanium - Admin';
      default:
        return '';
    }
  }
  return '';
};

const useUserTitle = () => {
  const [label, setLabel] = useState<string | JSX.Element>(getFormattedLabel());

  useEffect(() => {
    const subs = subscriber$.subscribe(() => {
      setLabel(getFormattedLabel());
    });

    return () => {
      if (subs) subs.unsubscribe();
    };
  }, []);

  return label;
};

type ActionProps = {
  label: string;
};

const Actions = ({ label }: ActionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'action-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size='small'
        aria-describedby={id}
        variant='contained'
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Typography variant={'h5'} gutterBottom>
            Action 1
          </Typography>
          <Typography variant={'h5'}>Action 2</Typography>
        </Box>
      </Popover>
    </>
  );
};

export const userTitleService = {
  updateLabel: () => {
    subscriberSource.next();
  },
  useUserTitle,
};
