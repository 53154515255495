import { colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const white = '#FFFFFF';
const mTanium = {
  50: 'rgb(216, 223, 232)',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3',
  600: '#1e88e5',
  700: '#1976d2',
  800: '#003f71',
  900: '#002f6c',
  A100: '#82b1ff',
  A200: '#448aff',
  A400: '#01579b',
  A700: '#2962ff',
};

export const palette: PaletteOptions = {
  primary: {
    contrastText: white,
    dark: mTanium[900],
    main: mTanium['A400'],
    light: mTanium[50],
  },
  // secondary: {
  //   contrastText: white,
  //   dark: mTanium[900],
  //   main: mTanium['A400'],
  //   light: mTanium[900],
  // },
  success: {
    contrastText: white,
    dark: 'rgb(30, 70, 32)',
    main: colors.green[600],
    light: 'rgb(237, 247, 237)',
  },
  info: {
    contrastText: white,
    dark: mTanium[900],
    main: mTanium[600],
    light: mTanium[400],
  },
  warning: {
    contrastText: white,
    dark: 'rgb(102, 60, 0)',
    main: colors.orange[600],
    light: 'rgb(255, 244, 229)',
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: '#6b6b6b',
  },
  background: {
    default: '#f5f5f5',
    paper: white,
  },
  divider: '#e0e0e0',
};
