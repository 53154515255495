import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { errorSnackbarService } from './error.service';

const msgs = defineMessages({
  MTACOM100: {
    id: 'errors.MTACOM100',
    defaultMessage: 'Invalid email',
    description: 'MTACOM100 Invalid email',
  },
  MTAURF400: {
    id: 'errors.MTAURF400',
    defaultMessage: 'Username not exists',
    description: 'MTAURF400 Username not exists',
  },
  MTACOM500: {
    id: 'errors.MTACOM500',
    defaultMessage: 'Internal server error',
    description: 'MTACOM500 Internal server error',
  },
  MTAFFV400: {
    id: 'errors.MTAFFV400',
    defaultMessage: 'Activation code invalid',
    description: 'MTAFFV400 Activation code invalid',
  },
  MTAUCF400: {
    id: 'errors.MTAUCF400',
    defaultMessage: 'Activation code invalid',
    description: 'MTAUCF400 Activation code invalid',
  },
  MTAUFP400: {
    id: 'errors.MTAUFP400',
    defaultMessage: 'Username(email) not found',
    description: 'MTAUFP400 Username(email) not found',
  },
  MTAUSO400: {
    id: 'errors.MTAUSO400',
    defaultMessage: 'Username(email) not found',
    description: 'MTAUSO400 Username(email) not found',
  },
  MTAULF401: {
    id: 'errors.MTAULF401',
    defaultMessage: 'Invalid username or password',
    description: 'MTAULF401 Invalid username or password',
  },
  MTAUUP400: {
    id: 'errors.MTAUUP400',
    defaultMessage: 'update password not successful',
    description: 'MTAUUP400 update password not successful',
  },
  MTAURT400: {
    id: 'errors.MTAURT400',
    defaultMessage: 'Problem in requesting new tokens(Refresh token Invalid)',
    description:
      'MTAURT400 Problem in requesting new tokens(Refresh token Invalid)',
  },
  MTAULF403: {
    id: 'errors.MTAULF403',
    defaultMessage:
      'You have exceeded all 3 attempts for login. Please wait for 5 minutes and try again.',
    description:
      'MTAULF403 You have exceeded all 3 attempts for login. Please wait for 5 minutes and try again.',
  },
  MTAECF400: {
    id: 'errors.MTAECF400',
    defaultMessage:
      'Username(email) already exists or Registration number already exist',
    description:
      'MTAECF400 Username(email) already exists  or Registration number already exist',
  },
  ERR_MTA_SESSION_TIMEOUT: {
    id: 'errors.ERR_MTA_SESSION_TIMEOUT',
    defaultMessage: 'Session timeout',
    description: 'ERR_MTA_SESSION_TIMEOUT Token has expired',
  },
});

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: theme.spacing(8),
  },
}));

export const ErrorSnackbar = () => {
  const { open, message } = errorSnackbarService.useSnackbarStatus();
  const intl = useIntl();
  const classes = useStyles();

  const getMatchedErrorMsg = () => {
    if (message) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const translatedMsg = (msgs as any)[message];
      if (translatedMsg) {
        return intl.formatMessage(translatedMsg);
      }
      return message;
    }
  };

  const handleClose = () =>
    // _: SyntheticEvent<Element>,
    // reason?: SnackbarCloseReason
    {
      // if (reason === 'clickaway') {
      //   return;
      // }
      errorSnackbarService.closeSnackbar();
    };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      // autoHideDuration={100000}
      onClose={handleClose}
      classes={{ anchorOriginTopCenter: classes.snackbar }}
    >
      <Alert
        onClose={handleClose}
        severity='error'
        elevation={6}
        // variant='filled'
      >
        {message && getMatchedErrorMsg()}
      </Alert>
    </Snackbar>
  );
};
