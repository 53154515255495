import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

import { successSnackbarService } from './success.service';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: theme.spacing(8),
  },
  alert: {
    whiteSpace: 'pre-line',
  },
}));

export const SuccessSnackbar = () => {
  const {
    open,
    message,
    severity,
  } = successSnackbarService.useSnackbarStatus();
  const classes = useStyles();

  const handleClose = () =>
    // _: SyntheticEvent<Element>,
    // reason?: SnackbarCloseReason
    {
      // if (reason === 'clickaway') {
      //   return;
      // }
      successSnackbarService.closeSnackbar();
    };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      classes={{ anchorOriginTopCenter: classes.snackbar }}
    >
      <Alert
        className={classes.alert}
        onClose={handleClose}
        severity={severity}
        elevation={6}
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
