import { palette } from '../palette';
import { typography } from '../typography';

export default {
  root: {
    ...typography.body1,
    fontFamily: 'Roboto !important',
    borderBottom: `1px solid ${palette.divider}`,
    fontSize: '0.875rem !important',
  },
};
