import {
  Avatar,
  Box,
  Divider,
  Drawer,
  DrawerProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { ROLE_LABELS } from '../../../utils/roles';
import { userStorage } from '../../../utils/storage';
import { DefaultLinks } from './DefaultLinks';
import { sidebarNavService } from './sidebar-nav/sidebar-nav.service';
import { SidebarNav } from './sidebar-nav/SidebarNav';

type SidebarProps = {
  variant: DrawerProps['variant'];
  open: boolean;
  onClose: () => void;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 256,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props: SidebarProps) => {
  const { open, variant, onClose } = props;

  const classes = useStyles();
  const userInfo = userStorage.get();

  const pages = sidebarNavService.useNavLinks(DefaultLinks());

  return (
    <Drawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <Box alignItems='center' display='flex' flexDirection='column' p={2}>
        <Avatar className={classes.avatar}>
          {userInfo?.email.charAt(0).toUpperCase()}
        </Avatar>
        <Typography color='textPrimary' variant='h5'>
          {userInfo?.displayName}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ROLE_LABELS[userInfo?.role!]
          }
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <SidebarNav className={classes.nav} pages={pages} />
      </Box>
    </Drawer>
  );
};

export { Sidebar };
