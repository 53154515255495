import { Env } from './interface';

export const env: Env = {
  baseUrl: 'https://g0j8ihn51k.execute-api.ap-southeast-1.amazonaws.com/dev',
  enterpriseBaseUrl:
    'https://v356m27xpg.execute-api.ap-southeast-1.amazonaws.com/dev',
  enterpriseRegBaseUrl:
    'https://og5zw82aj3.execute-api.ap-southeast-1.amazonaws.com/dev',
  enterpriseAppBaseUrl:
    'https://fivewi7szi.execute-api.ap-southeast-1.amazonaws.com/dev',
  policyBaseUrl:
    'https://9tz32kypff.execute-api.ap-southeast-1.amazonaws.com/dev',
  deviceBaseUrl:
    'https://izy0k07z64.execute-api.ap-southeast-1.amazonaws.com/dev',
  apiVersion: 'api/v1',
  environment: 'staging',
  isDev: false,
  recaptchaSiteKey: '6LeBDvYUAAAAAFt0FKDBAXxaAj3DaBMvzlNhPisa',
};
