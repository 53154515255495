import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { KeyboardArrowDown, Translate } from '@material-ui/icons';
import React, { useState } from 'react';

import { intlService, Langs } from '../../../services/intl.service';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  icon: {
    color: '#fff',
  },
  translateIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Languages = () => {
  const { defaultLang, changeLocale, langs } = intlService;
  const [locale, setLocale] = useState(defaultLang);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    changeLocale(event.target.value as Langs);
    setLocale(event.target.value as Langs);
  };

  return (
    <Select
      classes={{
        root: classes.root,
        icon: classes.icon,
      }}
      autoWidth
      value={locale}
      onChange={handleChange}
      disableUnderline
      IconComponent={KeyboardArrowDown}
      renderValue={(value) => (
        <>
          <Translate className={classes.translateIcon} />{' '}
          <div>{langs.find((lang) => lang.locale === value)?.label}</div>
        </>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      {langs.map((lang) => (
        <MenuItem key={lang.locale} value={lang.locale}>
          {lang.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export { Languages };
