import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Languages } from '../../_shared/languages/Languages';
import { getDefaultRoute } from '../../../modules/login/login.service';

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  rootHref: {
    // textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },
}));

const Topbar = () => {
  const classes = useStyles();

  return (
    <AppBar>
      <Toolbar>
        <RouterLink to={getDefaultRoute()}>
          <Typography variant='h4' classes={{ h4: classes.rootHref }}>
            mTanium
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Languages />
      </Toolbar>
    </AppBar>
  );
};

export { Topbar };
