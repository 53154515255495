import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Languages } from '../../_shared/languages/Languages';
import { getDefaultRoute } from '../../../modules/login/login.service';
import { Profile } from './profile/Profile';

type TopbarProps = {
  onSidebarOpen: () => void;
  className?: string;
};
const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  rootHref: {
    color: theme.palette.primary.contrastText,
  },
}));

const Topbar = (props: TopbarProps) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={className}>
      <Toolbar>
        <RouterLink to={getDefaultRoute()}>
          <Typography variant='h4' classes={{ h4: classes.rootHref }}>
            mTanium
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Languages />
        <Profile />
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export { Topbar };
