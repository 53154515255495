import { createContext } from 'react';

import { Enterprise, LoginRes } from '../interfaces';
import {
  emailStorage,
  enterpriseInfoStorage,
  permissionStorage,
  tokenStorage,
  TokenType,
  userStorage,
  UserType,
} from '../utils/storage';
import { Authorization } from './authorization.service';
import { userTitleService } from './user-title.service';

export type UserData = UserType | null;

export const AuthContext = createContext<UserData>(null);

export const logout = () => {
  userStorage.remove();
  tokenStorage.remove();
  clearPermissions();
  emailStorage.remove();
  enterpriseInfoStorage.remove();
};

export const currentUser = (): UserData => {
  return userStorage.get();
};

export const logUser = (data: LoginRes) => {
  userStorage.set({
    email: data.email,
    userId: data.muser_id,
    role: data.muser_type,
    displayName: data.muser_display_name,
  });

  const { idToken, accessToken, refreshToken } = data.tokens;
  tokenStorage.set({
    idToken: idToken.jwtToken,
    accessToken: accessToken.jwtToken,
    refreshToken: refreshToken.token,
  });

  permissionStorage.set(data.muser_permission);

  if (Object.keys(data.enterprise_info).length !== 0) {
    setEnterpriseInfo(data.enterprise_info as Enterprise);
  }
};

export const getTokens = (): TokenType | null => {
  return tokenStorage.get();
};

export const setEnterpriseInfo = (info: Enterprise) => {
  enterpriseInfoStorage.set(info);
  userTitleService.updateLabel();
};

const clearPermissions = () => {
  permissionStorage.remove();
  Authorization.instance = null;
};
