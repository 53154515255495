import { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { Subject } from 'rxjs';

import { env } from '../environments/env';
import { en, si, sv } from '../i18n/index';
import { localeStorage } from '../utils/storage';

export type Langs = 'sv' | 'en' | 'si';

const messages = { en, sv, si };

const langs: Array<{ locale: Langs; label: string }> = [
  {
    locale: 'en',
    label: 'English',
  },
  {
    locale: 'sv',
    label: 'Swedish',
  },
  {
    locale: 'si',
    label: 'සිංහල',
  },
];

const subscriberSource = new Subject<Langs>();
const subscriber$ = subscriberSource.asObservable();

let _locale: Langs;
const tmpLocale = localeStorage.get();
if (tmpLocale) {
  _locale = tmpLocale as Langs;
} else {
  _locale = navigator.language.split(/[-_]/)[0] as Langs;
}

const cache = createIntlCache();
const getIntl = (locale: Langs) => {
  return createIntl(
    {
      locale: locale,
      messages: messages[locale],
      onError: (err) => {
        if (env.isDev) {
          console.log(err.code);
          console.warn(err.message);
          console.log(err.name);
        }
      },
    },
    cache
  );
};

const useLocaleStatus = () => {
  const [locale, setLocale] = useState<Langs>(_locale);

  useEffect(() => {
    const subs = subscriber$.subscribe((t) => {
      setLocale(t);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      intlService.defaultLang = t;
      localeStorage.set(t);
    });

    return () => {
      if (subs) subs.unsubscribe();
    };
  }, []);

  return locale;
};

export const intlService = {
  defaultLang: _locale,
  changeLocale: (t: Langs) => {
    subscriberSource.next(t);
  },
  useLocaleStatus,
  getIntl,
  langs,
};
