import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

type SnackbarData = {
  open: boolean;
  message?: string;
};
const subscriberSource = new Subject<SnackbarData>();
const subscriber$ = subscriberSource.asObservable();

const useSnackbarStatus = (): SnackbarData => {
  const [data, setData] = useState({ open: false });

  useEffect(() => {
    const subs = subscriber$.subscribe((r) => {
      setData(r);
    });

    return () => {
      if (subs) subs.unsubscribe();
    };
  }, []);

  return data;
};

export const errorSnackbarService = {
  openSnackbar: (message: string) => {
    subscriberSource.next({ open: true, message });
  },
  closeSnackbar: () => {
    subscriberSource.next({ open: false });
  },
  useSnackbarStatus,
};
