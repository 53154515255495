import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '50%',
    left: 'calc(50% - 20px)',
    zIndex: 2000,
    transform: 'scale(2)',
  },
}));

type SpinnerProps = {
  show?: boolean;
};

export const Spinner = ({ show }: SpinnerProps) => {
  const { promiseInProgress } = usePromiseTracker();
  const classes = useStyles();

  return (
    <>
      {(promiseInProgress || show) && (
        <div className={classes.root}>
          <CircularProgress disableShrink thickness={4} />
        </div>
      )}
    </>
  );
};
