export const R_LOGIN = '/login';
export const R_SEND_PWD_RESET = '/send-pwd-reset';
export const R_RESET_PWD = '/reset-pwd';
export const R_REGISTRATION = '/registration';
export const R_NOTFOUND = '/not-found';
export const R_UNAUTHORIZED = '/deny-access';
export const R_REGISTER_VERIFICATION = '/register-verification';
export const R_M_ADMIN_DASHBOARD = '/dashboard';
export const R_M_ADMIN_ENTERPRISE_LIST = '/enterprises';
export const R_M_ADMIN_ENTERPRISE_CREATE = '/enterprises/create';
export const R_ENTERPRISE_REGISTRATION = '/enterprise-reg';
export const R_ENTERPRISE_REGISTRATION_DONE = '/enterprise-reg-done';
export const R_ENTERPRISE_SADMIN_ADMIN_LIST = '/enterprise-admins';
export const R_ENTERPRISE_SADMIN_ADMIN_CREATE = '/enterprise-admins/create';
export const R_ENTERPRISE_ADMIN_POLICIES = '/policies';
export const R_ENTERPRISE_ADMIN_POLICY_LIST = '/policies/list';
export const R_ENTERPRISE_ADMIN_POLICY_CREATE = '/policies/create';
export const R_ENTERPRISE_ADMIN_POLICY_MANAGE = '/policies/manage';
export const R_ENTERPRISE_ADMIN_POLICY_QR = '/policies/qr';
export const R_ENTERPRISE_ADMIN_APPS = '/enterprise-apps/';
export const R_ENTERPRISE_ADMIN_APPS_LIST = '/enterprise-apps/list';
export const R_ENTERPRISE_ADMIN_APPS_ADD = '/enterprise-apps/manage';
export const R_ENTERPRISE_ADMIN_DEVICES = '/devices';
export const R_ENTERPRISE_ADMIN_DEVICE_LIST = '/devices/list';
export const R_ENTERPRISE_ADMIN_DEVICE_INFO = '/devices/info';
export const R_ENTERPRISE_INFO = '/enterprise-info';
