// actions
export const A_M_ADMIN_DASHBOARD = '';
export const A_M_ADMIN_ENTERPRISE_LIST = 'enterprise_supper_admin_create';
export const A_M_ADMIN_ENTERPRISE_CREATE = 'enterprise_supper_admin_create';
export const A_ENTERPRISE_REGISTRATION = 'enterprise_admin_registration';
export const A_ENTERPRISE_REGISTRATION_DONE =
  'enterprise_admin_registration_done';
export const A_ENTERPRISE_SADMIN_ADMIN_LIST = 'enterprise_admin_create';
export const A_ENTERPRISE_SADMIN_ADMIN_CREATE = 'enterprise_admin_create';
export const A_ENTERPRISE_ADMIN_POLICY_LIST = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_POLICY_CREATE = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_POLICY_MANAGE = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_POLICY_QR = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_APPS = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_DEVICE_LIST = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_DEVICE_INFO = 'enterprise_device_create';
export const A_ENTERPRISE_INFO = 'enterprise_device_create';
export const A_ENTERPRISE_ADMIN_APPS_ADD = 'enterprise_device_create';
