import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorSnackbar, SuccessSnackbar } from './components/snackbars';
import { Spinner } from './components/spinner';
import Routes from './Routes';
import { AuthContext, currentUser } from './services/authentication.service';
import { intlService } from './services/intl.service';
import { theme } from './theme';

function App() {
  const locale = intlService.useLocaleStatus();

  return (
    <AuthContext.Provider value={currentUser()}>
      <RawIntlProvider value={intlService.getIntl(locale)} key={locale}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes />
          </Router>
          <Spinner />
        </ThemeProvider>
        <ErrorSnackbar />
        <SuccessSnackbar />
      </RawIntlProvider>
    </AuthContext.Provider>
  );
}

export default App;
