export enum UserRole {
  MTANIUMADMIN = 'MTANIUMADMIN',
  ENTERPRISESUPERADMIN = 'ENTERPRISESUPERADMIN',
  ENTERPRISEADMIN = 'ENTERPRISEADMIN',
}

export const ROLE_LABELS = {
  [UserRole.MTANIUMADMIN]: 'Mtanium Admin',
  [UserRole.ENTERPRISESUPERADMIN]: 'Enterprise Super Admin',
  [UserRole.ENTERPRISEADMIN]: 'Enterprise Admin',
};
