import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Confirmation } from '../../../../components/confirmation';
import { signout } from '../../../../services/signout.service';
import { R_LOGIN } from '../../../../utils/route-params';

const msgs = defineMessages({
  signout: {
    id: 'layouts.logoutDialog.signout',
    defaultMessage: 'Sign out',
  },
  signoutConfirmMsg: {
    id: 'layouts.logoutDialog.signoutConfirmMsg',
    defaultMessage: 'Are you sure you want to sign out from mTanium?',
  },
});

const Profile = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<
    null | (EventTarget & HTMLButtonElement)
  >();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const open = Boolean(anchorEl);
  const intl = useIntl();

  const handleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    setOpenConfirmation(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmation(false);
  };

  const handleConfirm = () => {
    setOpenConfirmation(false);
    signout().then(() => {
      history.push(R_LOGIN);
    });
  };

  return (
    <>
      <IconButton onClick={handleMenu} color='inherit'>
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>
          <FormattedMessage {...msgs.signout} />
        </MenuItem>
      </Menu>
      <Confirmation
        body={intl.formatMessage(msgs.signoutConfirmMsg)}
        open={openConfirmation}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export { Profile };
