import {
  A_ENTERPRISE_ADMIN_APPS,
  A_ENTERPRISE_ADMIN_DEVICE_LIST,
  A_ENTERPRISE_ADMIN_POLICY_LIST,
  A_ENTERPRISE_REGISTRATION,
  A_ENTERPRISE_REGISTRATION_DONE,
  A_ENTERPRISE_SADMIN_ADMIN_LIST,
} from '../utils/permission-actions';
import { UserRole } from '../utils/roles';
import {
  permissionStorage,
  PermissionType,
  userStorage,
} from '../utils/storage';

export class Authorization {
  static instance: Authorization | null = null;
  whitelistRules = [A_ENTERPRISE_REGISTRATION, A_ENTERPRISE_REGISTRATION_DONE];
  blacklistRules = {
    [UserRole.MTANIUMADMIN]: [
      A_ENTERPRISE_SADMIN_ADMIN_LIST,
      A_ENTERPRISE_ADMIN_POLICY_LIST,
      A_ENTERPRISE_ADMIN_APPS,
      A_ENTERPRISE_ADMIN_DEVICE_LIST,
    ],
    [UserRole.ENTERPRISESUPERADMIN]: [],
    [UserRole.ENTERPRISEADMIN]: [A_ENTERPRISE_SADMIN_ADMIN_LIST],
  };

  static getInstance = () => {
    if (Authorization.instance === null) {
      Authorization.instance = new Authorization();
    }
    return Authorization.instance;
  };
  rules: PermissionType = {};

  constructor() {
    this.rules = permissionStorage.get() || {};
    this.whitelistRules.forEach((rule) => {
      this.rules[rule] = true;
    });
    Object.keys(UserRole).forEach((role) => {
      if (role === userStorage.get()?.role) {
        this.blacklistRules[role].forEach((rule) => {
          this.rules[rule] = false;
        });
      }
    });
  }
}

export const hasPermission = (rule: string) => {
  const auth = Authorization.getInstance();

  if (auth.rules[rule]) {
    return true;
  }
  return false;
};
