import axios from 'axios';

import { errorSnackbarService } from '../components/snackbars';
import { env } from '../environments/env';
import { getTokens, logout } from '../services/authentication.service';
import { tokenStorage, userStorage } from './storage';
// import axiosRetry from 'axios-retry';

const TIMEOUT = 20000;
const instance = axios.create({
  baseURL: `${env.baseUrl}`,
});
// axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
// axiosRetry(instance, { retries: RETRY_COUNT });

const endPointNewToken = '/musers/requestnewtokens';

instance.interceptors.request.use((config) => {
  config.baseURL += `/${env.apiVersion}`;
  const tokens = getTokens();
  if (tokens) {
    config.headers.Authorization = tokens.idToken;
  }
  config.headers['content-type'] = 'application/x-www-form-urlencoded';
  config.timeout = TIMEOUT;

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== endPointNewToken &&
      error.response.data.message === 'The incoming token has expired'
    ) {
      originalRequest._retry = true;

      try {
        const res = await getTokenByRefreshToken();
        tokenStorage.set({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...tokenStorage.get()!,
          idToken: res.data.tokens.AuthenticationResult.IdToken,
          accessToken: res.data.tokens.AuthenticationResult.AccessToken,
        });
        const tokens = getTokens();

        if (tokens) {
          originalRequest.headers.Authorization = tokens.idToken;
        }
        return axios(originalRequest);
      } catch (err) {
        logout();
        window.location.href = '/';
        return Promise.reject(err);
      }
    }

    let msg;
    if (error.response) {
      if (error.response.data.error && error.response.data.error.message) {
        msg = error.response.data.error.message;
        if (msg === 'GOOERR400') {
          msg = error.response.data.error.errors.join('\n\n');
        }
      }
    } else if (error.code === 'ECONNABORTED') {
      msg = 'Network error';
    } else if (error.code === 'MTADE404') {
      msg = 'Device factory reset was not successful. Please contact support';
    } else {
      msg = 'Sever error';
    }

    errorSnackbarService.openSnackbar(msg);

    return Promise.reject(error);
  }
);

export const getTokenByRefreshToken = async () => {
  return await instance.post(endPointNewToken, {
    email: userStorage.get()?.email,
    // eslint-disable-next-line @typescript-eslint/camelcase
    refresh_token: tokenStorage.get()?.refreshToken,
  });
};

export { instance as axios };
