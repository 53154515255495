import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import { Pages } from '../../../../interfaces';

const subscriberSource = new Subject<Pages[]>();
const subscriber$ = subscriberSource.asObservable();

let defaultPages: Pages[];
const useNavLinks = (navLinks: Pages[]) => {
  defaultPages = navLinks;
  const [pages, setPages] = useState<Pages[]>(navLinks);

  useEffect(() => {
    const subs = subscriber$.subscribe((pages) => {
      setPages(pages);
    });

    return () => {
      if (subs) subs.unsubscribe();
    };
  }, []);

  return pages;
};

export const sidebarNavService = {
  updateNavLinks: (pages: Pages[]) => {
    subscriberSource.next(pages);
  },
  useNavLinks,
  updateDefault: () => {
    sidebarNavService.updateNavLinks(defaultPages);
  },
};
