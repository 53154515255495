import { trackPromise } from 'react-promise-tracker';

import { axios } from '../utils/axios';
import { currentUser, logout } from './authentication.service';

export const signout = () => {
  return trackPromise(
    axios
      .post('/musers/signout', {
        email: currentUser()?.email,
      })
      .then(() => {
        logout();
      })
  );
};
