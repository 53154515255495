import { Color } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

type SnackbarData = {
  open: boolean;
  message?: string;
  severity?: Color;
};
const subscriberSource = new Subject<SnackbarData>();
const subscriber$ = subscriberSource.asObservable();

const useSnackbarStatus = (): SnackbarData => {
  const [data, setData] = useState({ open: false });

  useEffect(() => {
    const subs = subscriber$.subscribe((r) => {
      setData(r);
    });

    return () => {
      if (subs) subs.unsubscribe();
    };
  }, []);

  return data;
};

export const successSnackbarService = {
  message: '',
  severity: '' as Color,
  openSnackbar: (message: string, severity: Color = 'success') => {
    successSnackbarService.message = message;
    successSnackbarService.severity = severity;
    subscriberSource.next({ open: true, message, severity });
  },
  closeSnackbar: () => {
    subscriberSource.next({
      open: false,
      message: successSnackbarService.message,
      severity: successSnackbarService.severity,
    });
  },
  useSnackbarStatus,
};
