import {
  Button,
  colors,
  Divider,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

import { Pages } from '../../../../interfaces';
import { hasPermission } from '../../../../services/authorization.service';
// import { userTitleService } from '../../../../services/user-title.service';

type SidebarNavProps = {
  className: string;
  pages: Pages[];
};

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    // color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonLbl: {
    color: colors.grey['A700'],
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  divider: {
    backgroundColor: colors.grey[100],
  },
  topDivider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(4),
  },
}));

const SidebarNav = (props: SidebarNavProps) => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  // const userTitle = userTitleService.useUserTitle();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {/* <ListItem classes={{ root: classes.subheader }}>{userTitle}</ListItem> */}
      {pages.map(
        (page) =>
          hasPermission(page.action) && (
            <ListItem className={classes.item} disableGutters key={page.title}>
              {page.borderTop && <Divider className={classes.topDivider} />}
              {page.href ? (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={NavLink}
                  to={page.href}
                  isActive={() => {
                    const withoutQS = page.href?.split('?')[0];
                    return page.exact
                      ? withoutQS === pathname
                      : withoutQS?.indexOf(path) === 0;
                  }}
                >
                  {page.icon && <div className={classes.icon}>{page.icon}</div>}
                  {page.title}
                </Button>
              ) : (
                <>
                  <Button
                    className={clsx(
                      classes.button,
                      page.isLabel && classes.buttonLbl
                    )}
                    disabled={!page.isLabel}
                    disableRipple={page.isLabel}
                  >
                    {page.icon && (
                      <div className={classes.icon}>{page.icon}</div>
                    )}
                    {page.title}
                  </Button>
                  {page.isLabel && <Divider className={classes.divider} />}
                </>
              )}
            </ListItem>
          )
      )}
    </List>
  );
};

export { SidebarNav };
