import loadable from '@loadable/component';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthRoute } from './components/auth-route';
import { BasicLayout } from './layouts/basic';
import { MainLayout } from './layouts/main';
import {
  A_ENTERPRISE_ADMIN_APPS,
  A_ENTERPRISE_ADMIN_DEVICE_LIST,
  A_ENTERPRISE_ADMIN_POLICY_LIST,
  A_ENTERPRISE_INFO,
  A_ENTERPRISE_REGISTRATION,
  A_ENTERPRISE_REGISTRATION_DONE,
  A_ENTERPRISE_SADMIN_ADMIN_CREATE,
  A_ENTERPRISE_SADMIN_ADMIN_LIST,
  A_M_ADMIN_DASHBOARD,
  A_M_ADMIN_ENTERPRISE_CREATE,
  A_M_ADMIN_ENTERPRISE_LIST,
} from './utils/permission-actions';
import {
  R_ENTERPRISE_ADMIN_APPS,
  R_ENTERPRISE_ADMIN_DEVICES,
  R_ENTERPRISE_ADMIN_POLICIES,
  R_ENTERPRISE_ADMIN_POLICY_CREATE,
  R_ENTERPRISE_INFO,
  R_ENTERPRISE_REGISTRATION,
  R_ENTERPRISE_REGISTRATION_DONE,
  R_ENTERPRISE_SADMIN_ADMIN_CREATE,
  R_ENTERPRISE_SADMIN_ADMIN_LIST,
  R_LOGIN,
  R_M_ADMIN_DASHBOARD,
  R_M_ADMIN_ENTERPRISE_CREATE,
  R_M_ADMIN_ENTERPRISE_LIST,
  R_NOTFOUND,
  R_REGISTER_VERIFICATION,
  R_REGISTRATION,
  R_RESET_PWD,
  R_SEND_PWD_RESET,
  R_UNAUTHORIZED,
} from './utils/route-params';

const Dashboard = loadable(() => import('./modules/dashboard/Dashboard'));
const Enterprises = loadable(() => import('./modules/enterprises/Enterprises'));
const EnterpriseCreate = loadable(
  () => import('./modules/enterprises/create/EnterpriseCreate')
);
const Login = loadable(() => import('./modules/login/Login'));
const SendPwdReset = loadable(
  () => import('./modules/send-pwd-reset/SendPwdReset')
);
const ResetPwd = loadable(() => import('./modules/reset-pwd/ResetPwd'));
const Registration = loadable(
  () => import('./modules/registration/Registration')
);
const RegisterVerification = loadable(
  () => import('./modules/register-verification/RegisterVerification')
);
const EnterpriseRegistration = loadable(
  () => import('./modules/enterprise-registration/EnterpriseRegistration')
);
const EnterpriseRegistrationWaiting = loadable(
  () =>
    import(
      './modules/enterprise-registration-waiting/EnterpriseRegistrationWaiting'
    )
);
const EnterpriseAdmins = loadable(
  () => import('./modules/enterprise-admins/EnterpriseAdmins')
);
const EnterpriseAdminCreate = loadable(
  () => import('./modules/enterprise-admins/create/EnterpriseAdminCreate')
);
const Policies = loadable(() => import('./modules/policies/Index'));
const Apps = loadable(() => import('./modules/apps/Index'));
const Devices = loadable(() => import('./modules/devices/Index'));
const EnterpriseInfo = loadable(
  () => import('./modules/enterprise-info/EnterpriseInfo')
);
const AccessDeny = loadable(() => import('./modules/access-deny/AccessDeny'));
const NotFound = loadable(() => import('./modules/not-found/NotFound'));

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from='/' to={R_LOGIN} />

      <Route
        path={[
          R_LOGIN,
          R_SEND_PWD_RESET,
          R_RESET_PWD,
          R_REGISTRATION,
          R_REGISTER_VERIFICATION,
          R_UNAUTHORIZED,
          R_NOTFOUND,
        ]}
      >
        <BasicLayout>
          <Switch>
            <Route path={R_LOGIN} component={Login} />
            <Route path={R_SEND_PWD_RESET} component={SendPwdReset} />
            <Route path={R_RESET_PWD} component={ResetPwd} />
            <Route path={R_REGISTRATION} component={Registration} />
            <Route
              path={R_REGISTER_VERIFICATION}
              component={RegisterVerification}
            />
            <Route path={R_UNAUTHORIZED} component={AccessDeny} />
            <Route path={R_NOTFOUND} component={NotFound} />
          </Switch>
        </BasicLayout>
      </Route>

      <Route
        path={[
          R_M_ADMIN_DASHBOARD,
          R_M_ADMIN_ENTERPRISE_LIST,
          R_M_ADMIN_ENTERPRISE_CREATE,
          R_ENTERPRISE_REGISTRATION,
          R_ENTERPRISE_REGISTRATION_DONE,
          R_ENTERPRISE_SADMIN_ADMIN_LIST,
          R_ENTERPRISE_ADMIN_POLICIES,
          R_ENTERPRISE_ADMIN_POLICY_CREATE,
          R_ENTERPRISE_ADMIN_APPS,
          R_ENTERPRISE_ADMIN_DEVICES,
          R_ENTERPRISE_INFO,
        ]}
      >
        <MainLayout>
          <Switch>
            <AuthRoute
              exact
              path={R_M_ADMIN_DASHBOARD}
              component={Dashboard}
              action={A_M_ADMIN_DASHBOARD}
            />
            <AuthRoute
              exact
              path={R_M_ADMIN_ENTERPRISE_LIST}
              component={Enterprises}
              action={A_M_ADMIN_ENTERPRISE_LIST}
            />
            <AuthRoute
              exact
              path={R_M_ADMIN_ENTERPRISE_CREATE}
              component={EnterpriseCreate}
              action={A_M_ADMIN_ENTERPRISE_CREATE}
            />
            <AuthRoute
              exact
              path={R_ENTERPRISE_REGISTRATION}
              component={EnterpriseRegistration}
              action={A_ENTERPRISE_REGISTRATION}
            />
            <AuthRoute
              exact
              path={R_ENTERPRISE_REGISTRATION_DONE}
              component={EnterpriseRegistrationWaiting}
              action={A_ENTERPRISE_REGISTRATION_DONE}
            />
            <AuthRoute
              exact
              path={R_ENTERPRISE_SADMIN_ADMIN_LIST}
              component={EnterpriseAdmins}
              action={A_ENTERPRISE_SADMIN_ADMIN_LIST}
            />
            <AuthRoute
              exact
              path={R_ENTERPRISE_SADMIN_ADMIN_CREATE}
              component={EnterpriseAdminCreate}
              action={A_ENTERPRISE_SADMIN_ADMIN_CREATE}
            />
            <AuthRoute
              path={R_ENTERPRISE_ADMIN_POLICIES}
              component={Policies}
              action={A_ENTERPRISE_ADMIN_POLICY_LIST}
            />
            <AuthRoute
              path={R_ENTERPRISE_ADMIN_APPS}
              component={Apps}
              action={A_ENTERPRISE_ADMIN_APPS}
            />
            <AuthRoute
              path={R_ENTERPRISE_ADMIN_DEVICES}
              component={Devices}
              action={A_ENTERPRISE_ADMIN_DEVICE_LIST}
            />
            <AuthRoute
              exact
              path={R_ENTERPRISE_INFO}
              component={EnterpriseInfo}
              action={A_ENTERPRISE_INFO}
            />
          </Switch>
        </MainLayout>
      </Route>

      <Redirect to={R_NOTFOUND} />
    </Switch>
  );
};

export default Routes;
