import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

type ConfirmationProps = {
  body: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmBtnLbl?: string;
  cancelBtnLbl?: string;
};

const msg = defineMessages({
  title: {
    id: 'components.confirmation.title',
    defaultMessage: 'Confirmation',
  },
  agree: {
    id: 'components.confirmation.agree',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'components.confirmation.cancel',
    defaultMessage: 'Cancel',
  },
});

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}));

export const Confirmation = (props: ConfirmationProps) => {
  const { body, open, onClose, onConfirm, confirmBtnLbl, cancelBtnLbl } = props;
  const [openDialog, setOpenDialog] = useState(open);
  const classes = useStyles();

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);
  const handleClose = () => {
    onClose();
  };
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <FormattedMessage {...msg.title} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={handleClose} variant='contained' autoFocus>
          {cancelBtnLbl ? cancelBtnLbl : <FormattedMessage {...msg.cancel} />}
        </Button>
        <Button onClick={handleConfirm} color='primary' variant='contained'>
          {confirmBtnLbl ? confirmBtnLbl : <FormattedMessage {...msg.agree} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
