import {
  Apps,
  Business,
  DeviceUnknown,
  Info,
  Policy,
  SupervisorAccount,
} from '@material-ui/icons';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Pages } from '../../../interfaces';
import {
  A_ENTERPRISE_ADMIN_APPS,
  A_ENTERPRISE_ADMIN_DEVICE_LIST,
  A_ENTERPRISE_ADMIN_POLICY_LIST,
  A_ENTERPRISE_INFO,
  A_ENTERPRISE_SADMIN_ADMIN_LIST,
  A_M_ADMIN_ENTERPRISE_LIST,
} from '../../../utils/permission-actions';
import {
  R_ENTERPRISE_ADMIN_APPS_LIST,
  R_ENTERPRISE_ADMIN_DEVICE_LIST,
  R_ENTERPRISE_ADMIN_POLICY_LIST,
  R_ENTERPRISE_INFO,
  R_ENTERPRISE_SADMIN_ADMIN_LIST,
  R_M_ADMIN_ENTERPRISE_LIST,
} from '../../../utils/route-params';
// import { userTitleService } from '../../../../services/user-title.service';

const msgs = defineMessages({
  dashboard: {
    id: 'layouts.Sidebar.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Router Dashboard',
  },
  login: {
    id: 'layouts.Sidebar.login',
    defaultMessage: 'Login',
    description: 'Router Login',
  },
  enterprises: {
    id: 'layouts.Sidebar.enterprises',
    defaultMessage: 'Enterprises',
    description: 'Enterprises sidebar',
  },
  enterpriseAdmins: {
    id: 'layouts.Sidebar.enterpriseAdmins',
    defaultMessage: 'Admins',
  },
  policies: {
    id: 'layouts.Sidebar.policies',
    defaultMessage: 'Policies',
  },
  apps: {
    id: 'layouts.Sidebar.apps',
    defaultMessage: 'Apps',
  },
  devices: {
    id: 'layouts.Sidebar.devices',
    defaultMessage: 'Devices',
  },
  users: {
    id: 'layouts.Sidebar.users',
    defaultMessage: 'Users',
  },
  enterpriseInfo: {
    id: 'layouts.Sidebar.enterpriseInfo',
    defaultMessage: 'Enterprise info',
  },
});

export const DefaultLinks = () => {
  const intl = useIntl();
  const navLinks: Pages[] = [
    // {
    //   title: intl.formatMessage(msgs.dashboard),
    //   href: R_M_ADMIN_DASHBOARD,
    //   icon: <DashboardIcon />,
    //   action: A_M_ADMIN_DASHBOARD,
    // },
    {
      title: intl.formatMessage(msgs.enterprises),
      href: R_M_ADMIN_ENTERPRISE_LIST,
      icon: <Business />,
      action: A_M_ADMIN_ENTERPRISE_LIST,
    },
    {
      title: intl.formatMessage(msgs.enterpriseAdmins),
      href: R_ENTERPRISE_SADMIN_ADMIN_LIST,
      icon: <SupervisorAccount />,
      action: A_ENTERPRISE_SADMIN_ADMIN_LIST,
    },
    {
      title: intl.formatMessage(msgs.policies),
      href: R_ENTERPRISE_ADMIN_POLICY_LIST,
      icon: <Policy />,
      action: A_ENTERPRISE_ADMIN_POLICY_LIST,
    },
    {
      title: intl.formatMessage(msgs.apps),
      href: R_ENTERPRISE_ADMIN_APPS_LIST,
      icon: <Apps />,
      action: A_ENTERPRISE_ADMIN_APPS,
    },
    {
      title: intl.formatMessage(msgs.devices),
      href: R_ENTERPRISE_ADMIN_DEVICE_LIST,
      icon: <DeviceUnknown />,
      action: A_ENTERPRISE_ADMIN_DEVICE_LIST,
    },
    {
      title: intl.formatMessage(msgs.enterpriseInfo),
      href: R_ENTERPRISE_INFO,
      icon: <Info />,
      action: A_ENTERPRISE_INFO,
      borderTop: true,
    },
  ];

  return navLinks;
};
