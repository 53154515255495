import { LoginRes } from '../../interfaces';
import { currentUser, logUser } from '../../services/authentication.service';
import { axios } from '../../utils/axios';
import {
  R_ENTERPRISE_ADMIN_POLICY_LIST,
  R_ENTERPRISE_REGISTRATION,
  R_ENTERPRISE_SADMIN_ADMIN_LIST,
  R_M_ADMIN_ENTERPRISE_LIST,
} from '../../utils/route-params';
import { enterpriseInfoStorage } from '../../utils/storage';

export const login = async(
  email: string,
  password: string,
  remember: boolean
) => {
  /* eslint-disable @typescript-eslint/camelcase */
  const { data } = await axios
    .post<LoginRes>('/musers/signin', {
      email,
      password,
      remember_me: remember,
    })
    .catch(() => {
      return { data: null };
    });
  /* eslint-enable */

  if (data) {
    logUser(data);
  }
  return data;
};

export const getDefaultRoute = () => {
  const currUser = currentUser();
  const enterpriseInfo = enterpriseInfoStorage.get();

  switch (currUser?.role) {
    case 'MTANIUMADMIN':
      return R_M_ADMIN_ENTERPRISE_LIST;
    case 'ENTERPRISESUPERADMIN':
      if (enterpriseInfo && enterpriseInfo.google_enterprise_token) {
        return R_ENTERPRISE_SADMIN_ADMIN_LIST;
      }
      return R_ENTERPRISE_REGISTRATION;
    case 'ENTERPRISEADMIN':
      return R_ENTERPRISE_ADMIN_POLICY_LIST;
    default:
      return '/';
  }
};
