import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import {
  AuthContext,
  currentUser,
} from '../../services/authentication.service';
import { hasPermission } from '../../services/authorization.service';
import { R_LOGIN, R_UNAUTHORIZED } from '../../utils/route-params';

type IProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  action: string;
} & RouteProps;

export const AuthRoute = ({
  component: Component,
  action,
  children,
  ...rest
}: IProps) => (
  <AuthContext.Consumer>
    {() => {
      return (
        <Route
          {...rest}
          render={(props) => {
            if (!currentUser()) {
              // not logged in so redirect to login page with the return url
              return (
                <Redirect
                  to={{ pathname: R_LOGIN, state: { from: props.location } }}
                />
              );
            }

            if (!hasPermission(action)) {
              return <Redirect to={R_UNAUTHORIZED} />;
            }

            // authorised so return component
            return <Component {...props}>{children} </Component>;
          }}
        />
      );
    }}
  </AuthContext.Consumer>
);
