import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme();

export default {
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
};
